<template>
    <div>
        <ts-page-title
            :title="$t('dashboard.pageTitle')"
            sub-title="header small text goes here..."
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('dashboard.pageTitle'),
                    active: true
                }
            ]"
        />

        <ts-panel>
            <ts-panel-wrapper>
                <div
                    class="tw-w-full tw-flex tw-items-center tw-justify-center"
					style="height: 80vh;"
                   
                >
                    <img
                        src="https://t4.ftcdn.net/jpg/00/89/02/67/360_F_89026793_eyw5a7WCQE0y1RHsizu41uhj7YStgvAA.jpg"
                        alt=""
						style="height: 50%; width: 50%; object-fit: contain;"
                    />
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "dashboard-v3",
    data() {
        function handleGetDate(minusDate) {
            var d = new Date();
            d = d.setDate(d.getDate() - minusDate);
            return d;
        }

        var convertNumberWithCommas = function(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        };

        return {
            dateRange: {
                opens: "right",
                singleDatePicker: false,
                timePicker: false,
                timePicker24Hour: false,
                showWeekNumbers: false,
                showDropdowns: false,
                autoApply: false,
                linkedCalendars: false,
                range: {
                    startDate: moment().subtract(7, "days"),
                    endDate: moment(),
                    prevStartDate: moment()
                        .subtract("days", 15)
                        .format("D MMMM"),
                    prevEndDate: moment()
                        .subtract("days", 8)
                        .format("D MMMM YYYY")
                }
            },
            areaChart: {
                rightAlignYAxis: false,
                showControls: false,
                clipEdge: false,
                data: [
                    {
                        key: "Unique Visitors",
                        color: "#5AC8FA",
                        values: [
                            { x: handleGetDate(77), y: 13 },
                            { x: handleGetDate(76), y: 13 },
                            { x: handleGetDate(75), y: 6 },
                            { x: handleGetDate(73), y: 6 },
                            { x: handleGetDate(72), y: 6 },
                            { x: handleGetDate(71), y: 5 },
                            { x: handleGetDate(70), y: 5 },
                            { x: handleGetDate(69), y: 5 },
                            { x: handleGetDate(68), y: 6 },
                            { x: handleGetDate(67), y: 7 },
                            { x: handleGetDate(66), y: 6 },
                            { x: handleGetDate(65), y: 9 },
                            { x: handleGetDate(64), y: 9 },
                            { x: handleGetDate(63), y: 8 },
                            { x: handleGetDate(62), y: 10 },
                            { x: handleGetDate(61), y: 10 },
                            { x: handleGetDate(60), y: 10 },
                            { x: handleGetDate(59), y: 10 },
                            { x: handleGetDate(58), y: 9 },
                            { x: handleGetDate(57), y: 9 },
                            { x: handleGetDate(56), y: 10 },
                            { x: handleGetDate(55), y: 9 },
                            { x: handleGetDate(54), y: 9 },
                            { x: handleGetDate(53), y: 8 },
                            { x: handleGetDate(52), y: 8 },
                            { x: handleGetDate(51), y: 8 },
                            { x: handleGetDate(50), y: 8 },
                            { x: handleGetDate(49), y: 8 },
                            { x: handleGetDate(48), y: 7 },
                            { x: handleGetDate(47), y: 7 },
                            { x: handleGetDate(46), y: 6 },
                            { x: handleGetDate(45), y: 6 },
                            { x: handleGetDate(44), y: 6 },
                            { x: handleGetDate(43), y: 6 },
                            { x: handleGetDate(42), y: 5 },
                            { x: handleGetDate(41), y: 5 },
                            { x: handleGetDate(40), y: 4 },
                            { x: handleGetDate(39), y: 4 },
                            { x: handleGetDate(38), y: 5 },
                            { x: handleGetDate(37), y: 5 },
                            { x: handleGetDate(36), y: 5 },
                            { x: handleGetDate(35), y: 7 },
                            { x: handleGetDate(34), y: 7 },
                            { x: handleGetDate(33), y: 7 },
                            { x: handleGetDate(32), y: 10 },
                            { x: handleGetDate(31), y: 9 },
                            { x: handleGetDate(30), y: 9 },
                            { x: handleGetDate(29), y: 10 },
                            { x: handleGetDate(28), y: 11 },
                            { x: handleGetDate(27), y: 11 },
                            { x: handleGetDate(26), y: 8 },
                            { x: handleGetDate(25), y: 8 },
                            { x: handleGetDate(24), y: 7 },
                            { x: handleGetDate(23), y: 8 },
                            { x: handleGetDate(22), y: 9 },
                            { x: handleGetDate(21), y: 8 },
                            { x: handleGetDate(20), y: 9 },
                            { x: handleGetDate(19), y: 10 },
                            { x: handleGetDate(18), y: 9 },
                            { x: handleGetDate(17), y: 10 },
                            { x: handleGetDate(16), y: 16 },
                            { x: handleGetDate(15), y: 17 },
                            { x: handleGetDate(14), y: 16 },
                            { x: handleGetDate(13), y: 17 },
                            { x: handleGetDate(12), y: 16 },
                            { x: handleGetDate(11), y: 15 },
                            { x: handleGetDate(10), y: 14 },
                            { x: handleGetDate(9), y: 24 },
                            { x: handleGetDate(8), y: 18 },
                            { x: handleGetDate(7), y: 15 },
                            { x: handleGetDate(6), y: 14 },
                            { x: handleGetDate(5), y: 16 },
                            { x: handleGetDate(4), y: 16 },
                            { x: handleGetDate(3), y: 17 },
                            { x: handleGetDate(2), y: 7 },
                            { x: handleGetDate(1), y: 7 },
                            { x: handleGetDate(0), y: 7 }
                        ]
                    },
                    {
                        key: "Page Views",
                        color: "#348fe2",
                        values: [
                            { x: handleGetDate(77), y: 14 },
                            { x: handleGetDate(76), y: 13 },
                            { x: handleGetDate(75), y: 15 },
                            { x: handleGetDate(73), y: 14 },
                            { x: handleGetDate(72), y: 13 },
                            { x: handleGetDate(71), y: 15 },
                            { x: handleGetDate(70), y: 16 },
                            { x: handleGetDate(69), y: 16 },
                            { x: handleGetDate(68), y: 14 },
                            { x: handleGetDate(67), y: 14 },
                            { x: handleGetDate(66), y: 13 },
                            { x: handleGetDate(65), y: 12 },
                            { x: handleGetDate(64), y: 13 },
                            { x: handleGetDate(63), y: 13 },
                            { x: handleGetDate(62), y: 15 },
                            { x: handleGetDate(61), y: 16 },
                            { x: handleGetDate(60), y: 16 },
                            { x: handleGetDate(59), y: 17 },
                            { x: handleGetDate(58), y: 17 },
                            { x: handleGetDate(57), y: 18 },
                            { x: handleGetDate(56), y: 15 },
                            { x: handleGetDate(55), y: 15 },
                            { x: handleGetDate(54), y: 15 },
                            { x: handleGetDate(53), y: 19 },
                            { x: handleGetDate(52), y: 19 },
                            { x: handleGetDate(51), y: 18 },
                            { x: handleGetDate(50), y: 18 },
                            { x: handleGetDate(49), y: 17 },
                            { x: handleGetDate(48), y: 16 },
                            { x: handleGetDate(47), y: 18 },
                            { x: handleGetDate(46), y: 18 },
                            { x: handleGetDate(45), y: 18 },
                            { x: handleGetDate(44), y: 16 },
                            { x: handleGetDate(43), y: 14 },
                            { x: handleGetDate(42), y: 14 },
                            { x: handleGetDate(41), y: 13 },
                            { x: handleGetDate(40), y: 14 },
                            { x: handleGetDate(39), y: 13 },
                            { x: handleGetDate(38), y: 10 },
                            { x: handleGetDate(37), y: 9 },
                            { x: handleGetDate(36), y: 10 },
                            { x: handleGetDate(35), y: 11 },
                            { x: handleGetDate(34), y: 11 },
                            { x: handleGetDate(33), y: 11 },
                            { x: handleGetDate(32), y: 10 },
                            { x: handleGetDate(31), y: 9 },
                            { x: handleGetDate(30), y: 10 },
                            { x: handleGetDate(29), y: 13 },
                            { x: handleGetDate(28), y: 14 },
                            { x: handleGetDate(27), y: 14 },
                            { x: handleGetDate(26), y: 13 },
                            { x: handleGetDate(25), y: 12 },
                            { x: handleGetDate(24), y: 11 },
                            { x: handleGetDate(23), y: 13 },
                            { x: handleGetDate(22), y: 13 },
                            { x: handleGetDate(21), y: 13 },
                            { x: handleGetDate(20), y: 13 },
                            { x: handleGetDate(19), y: 14 },
                            { x: handleGetDate(18), y: 13 },
                            { x: handleGetDate(17), y: 13 },
                            { x: handleGetDate(16), y: 19 },
                            { x: handleGetDate(15), y: 21 },
                            { x: handleGetDate(14), y: 22 },
                            { x: handleGetDate(13), y: 25 },
                            { x: handleGetDate(12), y: 24 },
                            { x: handleGetDate(11), y: 24 },
                            { x: handleGetDate(10), y: 22 },
                            { x: handleGetDate(9), y: 16 },
                            { x: handleGetDate(8), y: 15 },
                            { x: handleGetDate(7), y: 12 },
                            { x: handleGetDate(6), y: 12 },
                            { x: handleGetDate(5), y: 15 },
                            { x: handleGetDate(4), y: 15 },
                            { x: handleGetDate(3), y: 15 },
                            { x: handleGetDate(2), y: 18 },
                            { x: handleGetDate(2), y: 18 },
                            { x: handleGetDate(0), y: 17 }
                        ]
                    }
                ]
            },
            map: {
                styles: [
                    {
                        featureType: "all",
                        elementType: "labels.text.fill",
                        stylers: [{ saturation: 36 }, { lightness: 40 }]
                    },
                    {
                        featureType: "all",
                        elementType: "labels.text.stroke",
                        stylers: [
                            { visibility: "on" },
                            { color: "#000000" },
                            { lightness: 16 }
                        ]
                    },
                    {
                        featureType: "all",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }]
                    },
                    {
                        featureType: "administrative",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#2d353c" }, { lightness: 20 }]
                    },
                    {
                        featureType: "administrative",
                        elementType: "geometry.stroke",
                        stylers: [
                            { color: "#000000" },
                            { lightness: 17 },
                            { weight: 1.2 }
                        ]
                    },
                    {
                        featureType: "administrative",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d8d8d8" }]
                    },
                    {
                        featureType: "administrative.neighborhood",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#ff0000" }]
                    },
                    {
                        featureType: "administrative.land_parcel",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#2d353c" }]
                    },
                    {
                        featureType: "landscape",
                        elementType: "geometry",
                        stylers: [{ color: "#000000" }, { lightness: 20 }]
                    },
                    {
                        featureType: "landscape",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#2d353c" }]
                    },
                    {
                        featureType: "landscape",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#00acac" }]
                    },
                    {
                        featureType: "landscape.man_made",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#2d353c" }]
                    },
                    {
                        featureType: "poi",
                        elementType: "geometry",
                        stylers: [{ color: "#000000" }, { lightness: 21 }]
                    },
                    {
                        featureType: "poi",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#2d353c" }]
                    },
                    {
                        featureType: "poi",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#575d63" }]
                    },
                    {
                        featureType: "road",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#348fe2" }]
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#000000" }, { lightness: 17 }]
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry.stroke",
                        stylers: [
                            { color: "#000000" },
                            { lightness: 29 },
                            { weight: 0.2 }
                        ]
                    },
                    {
                        featureType: "road.highway.controlled_access",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#575d63" }]
                    },
                    {
                        featureType: "road.arterial",
                        elementType: "geometry",
                        stylers: [{ color: "#000000" }, { lightness: 18 }]
                    },
                    {
                        featureType: "road.arterial",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#575d63" }]
                    },
                    {
                        featureType: "road.local",
                        elementType: "geometry",
                        stylers: [{ color: "#000000" }, { lightness: 16 }]
                    },
                    {
                        featureType: "road.local",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#575d63" }]
                    },
                    {
                        featureType: "transit",
                        elementType: "geometry",
                        stylers: [{ color: "#000000" }, { lightness: 19 }]
                    },
                    {
                        featureType: "transit",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#2d353c" }]
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [{ color: "#000000" }, { lightness: 17 }]
                    },
                    {
                        featureType: "water",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#1a1f23" }]
                    }
                ]
            },
            totalSalesChart: {
                options: {
                    chart: {
                        type: "line",
                        width: 200,
                        height: 36,
                        sparkline: {
                            enabled: true
                        },
                        stacked: true
                    },
                    stroke: {
                        curve: "smooth",
                        width: 3
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            opacityFrom: 1,
                            opacityTo: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: "#348fe2",
                                    opacity: 1
                                },
                                {
                                    offset: 100,
                                    color: "#8753de",
                                    opacity: 1
                                }
                            ]
                        }
                    },
                    tooltip: {
                        theme: "dark",
                        fixed: {
                            enabled: false
                        },
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                                formatter: function() {
                                    return "";
                                }
                            },
                            formatter: value => {
                                return "$" + convertNumberWithCommas(value);
                            }
                        },
                        marker: {
                            show: false
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 1500,
                            options: {
                                chart: {
                                    width: 130
                                }
                            }
                        },
                        {
                            breakpoint: 1300,
                            options: {
                                chart: {
                                    width: 100
                                }
                            }
                        },
                        {
                            breakpoint: 1200,
                            options: {
                                chart: {
                                    width: 200
                                }
                            }
                        },
                        {
                            breakpoint: 576,
                            options: {
                                chart: {
                                    width: 180
                                }
                            }
                        },
                        {
                            breakpoint: 400,
                            options: {
                                chart: {
                                    width: 120
                                }
                            }
                        }
                    ]
                },
                series: [
                    {
                        data: [
                            9452.37,
                            11018.87,
                            7296.37,
                            6274.29,
                            7924.05,
                            6581.34,
                            12918.14
                        ]
                    }
                ]
            },
            conversionRateChart: {
                options: {
                    chart: {
                        type: "line",
                        width: 160,
                        height: 28,
                        sparkline: {
                            enabled: true
                        }
                    },
                    stroke: {
                        curve: "smooth",
                        width: 3
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            opacityFrom: 1,
                            opacityTo: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: "#ff5b57",
                                    opacity: 1
                                },
                                {
                                    offset: 50,
                                    color: "#f59c1a",
                                    opacity: 1
                                },
                                {
                                    offset: 100,
                                    color: "#90ca4b",
                                    opacity: 1
                                }
                            ]
                        }
                    },
                    labels: [
                        "Jun 23",
                        "Jun 24",
                        "Jun 25",
                        "Jun 26",
                        "Jun 27",
                        "Jun 28",
                        "Jun 29"
                    ],
                    xaxis: {
                        crosshairs: {
                            width: 1
                        }
                    },
                    tooltip: {
                        theme: "dark",
                        fixed: {
                            enabled: false
                        },
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                                formatter: function() {
                                    return "";
                                }
                            },
                            formatter: value => {
                                return value + "%";
                            }
                        },
                        marker: {
                            show: false
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 1500,
                            options: {
                                chart: {
                                    width: 120
                                }
                            }
                        },
                        {
                            breakpoint: 1300,
                            options: {
                                chart: {
                                    width: 100
                                }
                            }
                        },
                        {
                            breakpoint: 1200,
                            options: {
                                chart: {
                                    width: 160
                                }
                            }
                        },
                        {
                            breakpoint: 900,
                            options: {
                                chart: {
                                    width: 120
                                }
                            }
                        },
                        {
                            breakpoint: 576,
                            options: {
                                chart: {
                                    width: 180
                                }
                            }
                        },
                        {
                            breakpoint: 400,
                            options: {
                                chart: {
                                    width: 120
                                }
                            }
                        }
                    ]
                },
                series: [
                    {
                        data: [2.68, 2.93, 2.04, 1.61, 1.88, 1.62, 2.8]
                    }
                ]
            },
            storeSessionChart: {
                options: {
                    chart: {
                        type: "line",
                        width: 160,
                        height: 28,
                        sparkline: {
                            enabled: true
                        },
                        stacked: false
                    },
                    stroke: {
                        curve: "smooth",
                        width: 3
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            opacityFrom: 1,
                            opacityTo: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: "#00acac",
                                    opacity: 1
                                },
                                {
                                    offset: 50,
                                    color: "#348fe2",
                                    opacity: 1
                                },
                                {
                                    offset: 100,
                                    color: "#5AC8FA",
                                    opacity: 1
                                }
                            ]
                        }
                    },
                    labels: [
                        "Jun 23",
                        "Jun 24",
                        "Jun 25",
                        "Jun 26",
                        "Jun 27",
                        "Jun 28",
                        "Jun 29"
                    ],
                    xaxis: {
                        crosshairs: {
                            width: 1
                        }
                    },
                    tooltip: {
                        theme: "dark",
                        fixed: {
                            enabled: false
                        },
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                                formatter: function() {
                                    return "";
                                }
                            },
                            formatter: value => {
                                return convertNumberWithCommas(value);
                            }
                        },
                        marker: {
                            show: false
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 1500,
                            options: {
                                chart: {
                                    width: 120
                                }
                            }
                        },
                        {
                            breakpoint: 1300,
                            options: {
                                chart: {
                                    width: 100
                                }
                            }
                        },
                        {
                            breakpoint: 1200,
                            options: {
                                chart: {
                                    width: 160
                                }
                            }
                        },
                        {
                            breakpoint: 900,
                            options: {
                                chart: {
                                    width: 120
                                }
                            }
                        },
                        {
                            breakpoint: 576,
                            options: {
                                chart: {
                                    width: 180
                                }
                            }
                        },
                        {
                            breakpoint: 400,
                            options: {
                                chart: {
                                    width: 120
                                }
                            }
                        }
                    ]
                },
                series: [
                    {
                        data: [10812, 11393, 7311, 6834, 9612, 11200, 13557]
                    }
                ]
            }
        };
    },
    methods: {
        updateValues(d) {
            var startDate = moment(d.startDate);
            var endDate = moment(d.endDate);
            var gap = endDate.diff(startDate, "days");

            this.dateRange.range.prevStartDate = moment(startDate)
                .subtract("days", gap)
                .format("D MMMM");
            this.dateRange.range.prevEndDate = moment(startDate)
                .subtract("days", 1)
                .format("D MMMM YYYY");
            console.log(d.startDate);
        },
        formatDate(d) {
            var monthsName = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ];
            d = new Date(d);
            d = monthsName[d.getMonth()] + " " + d.getDate();
            return d;
        }
    },
    filters: {
        date: function(value) {
            if (value) {
                return moment(String(value)).format("D MMMM YYYY");
            }
        }
    }
};
</script>
